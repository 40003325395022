import React from "react"
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'

export default function BouncingArrowIcon({
    size = 24,
    color = '#000',
    className = "",
}) {
    const baseStyle = {
        animation: 'bounce 2s ease-in-out infinite'
    }

    return (
        <div className={`${className}`} style={baseStyle}>
            <KeyboardArrowDownRoundedIcon
                sx={{
                    fontSize: size,
                    color: color,
                    padding: 0,
                    margin: 0,
                }}
            />
        </div>
    )
}

const style = document.createElement('style')
style.textContent = `
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
  }
`
document.head.appendChild(style)
