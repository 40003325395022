import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, Modal, TextField, useMediaQuery } from '@mui/material'
import { getSocialLinks, addSocialLink, updateSocialLink } from "../../../../../service/SocialLinkDataService"
import { SocialLink } from '../../../../../model/SocialLink'

const socials = [
    { id: 0, name: 'Email' },
    { id: 21, name: 'Website' },
    { id: 1, name: 'Instagram' },
    { id: 2, name: 'Facebook' },
    { id: 3, name: 'Twitter' },
    { id: 4, name: 'TikTok' },
    { id: 5, name: 'Pinterest' },
    { id: 6, name: 'Snapchat' },
    { id: 7, name: 'Reddit' },
    { id: 8, name: 'WhatsApp' },
    { id: 9, name: 'Tumblr' },
    { id: 10, name: 'YouTube' },
    { id: 11, name: 'Twitch' },
    { id: 12, name: 'Discord' },
    { id: 13, name: 'Spotify' },
    { id: 14, name: 'Amazon' },
    { id: 15, name: 'Ebay' },
    { id: 16, name: 'Etsy' },
    { id: 17, name: 'GitHub' },
    { id: 18, name: 'LinkedIn' },
    { id: 19, name: 'PayPal' },
    { id: 20, name: 'Vine' },
]

export function SocialLinkModal({ open, onClose, onAddIcon, pageId }) {
    const [urls, setUrls] = useState([])
    const [existingLinks, setExistingLinks] = useState([])
    const isMobile = useMediaQuery('(max-width:1050px)')


    useEffect(() => {
        if (open) {
            fetchSocialLinks(pageId)
        } else {
            setUrls([])
        }
    }, [open])

    const fetchSocialLinks = async () => {
        try {
            const response = await getSocialLinks(pageId)
            if (response.length > 0) {
                setExistingLinks(response)
                setUrls(response)
            }
        } catch (error) {
            console.error('Failed to fetch social links:', error)
        }
    }

    const handleInputChange = (event, iconCode) => {
        const newUrls = urls.map((link) =>
            link.icon === iconCode ? { ...link, url: event.target.value } : link
        )

        // adding new social link if it doesn't exist
        if (!newUrls.some((link) => link.icon === iconCode)) {
            newUrls.push(new SocialLink("", event.target.value, iconCode, ""))
        }

        setUrls(newUrls)
    }

    const handleAddLinks = async () => {
        try {
            const promises = urls.map(async (link) => {
                const existingLink = existingLinks.find(
                    (existingLink) => existingLink.icon === link.icon
                )

                if (!existingLink || link.url !== existingLink.url) {
                    if (existingLink) {
                        // Update existing link
                        const socialLink = new SocialLink(link.id, link.url, link.icon, pageId)
                        await updateSocialLink(socialLink)
                    } else {
                        // Add new link
                        const socialLink = new SocialLink("", link.url, link.icon, pageId)
                        await addSocialLink(socialLink)
                    }
                }
            })

            await Promise.all(promises)
            onAddIcon(urls)
            onClose()
        } catch (error) {
            console.error('Failed to save social links:', error)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: isMobile ? 300 : 420,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Social Icon
                </Typography>
                <Box
                    sx={{
                        height: '60vh',
                        overflow: 'scroll',
                        gap: 1,
                    }}
                >
                    {socials.map((item) => (
                        <TextField
                            key={item.id}
                            margin="normal"
                            fullWidth
                            id={item.name}
                            label={item.name}
                            name={item.name}
                            autoComplete={item.name}
                            value={
                                urls.find((link) => link.icon === item.id)?.url || ''
                            }
                            placeholder={`Paste your ${item.name} link here`}
                            onChange={(e) => handleInputChange(e, item.id)}
                        />
                    ))}
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddLinks}
                    sx={{ mt: 2 }}
                >
                    Done
                </Button>
            </Box>
        </Modal>
    )
}
