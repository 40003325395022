import { Button, CardMedia, Typography, useMediaQuery } from "@mui/material"

export default function ProblemStatement() {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <article style={{
                margin: isMobile ? '.8rem' : '0 10vw',
                borderRadius: '20px',
                background: '#f4f4f4',
                padding: isMobile ? '2rem 1rem' : '2rem',
            }}>
                <Typography variant={isMobile ? 'h4' : 'h3'} component="h3"
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'inherit',
                        fontWeight: 900,
                        textAlign: 'center',
                        m: '0 0 2rem 0',
                    }}>
                    Why Vibio?
                </Typography>

                <section style={{
                    display: "flex",
                    flexDirection: isMobile ? 'column' : 'row-reverse',
                    justifyContent: 'space-evenly',
                }}>
                    <Typography variant="h6" component="h6"
                        sx={{
                            color: 'text.second',
                            fontFamily: 'inherit',
                            fontWeight: 400,
                            p: isMobile ? 0 : '1rem 4rem',
                            flex: 2.2,
                        }}>
                        <p>
                            Vibio lets you create a video-powered link in bio profile, perfect for showcasing yourself,
                            your work, or the products and services you offer. Promote your social channels and use the
                            looping video to share your story, your passion, or your creativity. We believe you'll let
                            your personality shine through!
                        </p>
                        <p>
                            Vibio is a gateway that will connect your visitors to anything you have to offer as a creator.
                            All you are, combined in one video link in bio profile. What are you waiting for?
                        </p>
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            href="/login"
                            sx={{
                                m: '2rem 0',
                                p: '.8rem .6rem',
                                width: '100%',
                                fontFamily: 'inherit',
                                backgroundColor: 'primary.main',
                                color: 'text.regular',
                                textTransform: 'none',
                            }}
                        >
                            <span style={{ fontSize: '1.1rem' }}>Start My Vibio Experience</span>
                        </Button>
                    </Typography>
                    <CardMedia
                        muted
                        playsInline
                        component="video"
                        src="/jessica-2.mp4"
                        title="Product showcase 1"
                        autoPlay
                        loop
                        sx={{
                            m: 0,
                            p: 0,
                            height: '90vh',
                            objectFit: 'contain',
                            flex: 3,
                        }}
                    />

                </section>
            </article>
        </>
    )
}
