import React from "react"
import { motion } from "framer-motion"
import { Avatar, Chip, Typography } from '@mui/material'


export function Profile({ isFullView, userData }) {

    return (
        <motion.div
            animate={{ y: 5, x: 6 }}
            style={{
                display: 'flex',
                flexDirection: isFullView ? 'column' : 'column',
                alignItems: isFullView ? 'center' : 'center',
                paddingBottom: isFullView ? '1.4rem' : '.8rem',
            }}
        >
            <Avatar
                alt="User profile pic"
                src={userData?.profilePicture}
                sx=
                {{
                    width: '4.1rem',
                    height: '4.1rem',
                    m: '.4rem .2rem',
                }}
            />
            <motion.div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {userData?.username && (
                    <Chip
                        label={`@${userData.username}`}
                        color="primary"
                        size="small"
                        sx={{
                            m: '.4rem 0',
                            p: 0,
                        }}
                    />
                )}
            </motion.div>
            {/* bio */}
            {isFullView && userData?.bio && (
                <Typography variant="body2">
                    {userData.bio}
                </Typography>
            )}
        </motion.div>
    )
}
