import { Button, Paper, Typography, useMediaQuery } from "@mui/material";
import { useAuth } from "../../../context/SecurityContextProvider";


export default function SubscriptionSettings() {
    const { userData } = useAuth()
    const isMobile = useMediaQuery('(max-width:1050px)')


    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    m: isMobile ? '1rem 0 0 0' : 2,
                    borderRadius: '10px',
                }}>
                <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                        fontWeight: 600,
                        fontFamily: "inherit",
                    }}
                >
                    Subscription Tier
                </Typography>

                <Typography
                    variant="body2"
                    component="p"
                    sx={{
                        fontWeight: 450,
                        fontFamily: "inherit",
                        p: '.2rem 0',
                    }}
                >
                    {/* To upgrage or cancel your tier click on "Manage"
                    <hr/> */}
                    You're in our Light tier in this "Early Release" version.
                    <br />
                    The Light tier is the free tier. We're working tirelessly
                    <br />
                    on our other tiers with great features
                    asked by our users just like you!
                </Typography>

                <Paper
                    elevation={2}
                    sx={{
                        p: 1,
                        m: '1rem 0',
                        borderRadius: '10px',
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>

                    <Typography
                        variant="body1"
                        component="h6"
                        sx={{
                            fontWeight: 450,
                            fontFamily: "inherit",
                            m: '.6rem .4rem',
                            color: '#fff',
                        }}
                    >
                        {userData.tier.toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}
                    </Typography>

                    <Button
                        variant='contained'
                        type='button'
                        color="inherit"
                        sx={{
                            fontSize: '70%',
                            textTransform: 'none',
                            m: '.2rem .4rem',
                            p: '.3rem',
                        }}
                    >
                        Free
                    </Button>


                </Paper>

            </Paper>
        </>
    )
}

