import { Box, Paper, Typography, useMediaQuery } from "@mui/material"
import React from "react"


const benefits = [
    {
        pos: 1,
        title: 'Engaging Bios',
        content: "Create more engaging and creative link-in-bio for you and your business. Express yourself to your audience just the way you like!",
    },
    {
        pos: 2,
        title: 'All In One Place',
        content: "Link your social media accounts, add any links you have; products, calendar, website, etc. Your online & offline presence in one place complimented with your amazing video!",
    },
    {
        pos: 3,
        title: "It's the video",
        content: "Vibio allows your visitors to have the best experience out of your video-bio. Let your creativity shine! Introduce yourself, your work or anything you like. We believe in your creativity to take your bio page to the next level!!",
    },
]

export default function BenefitsSection({ title, content, alignment }) {
    const isMobile = useMediaQuery('(max-width:1050px)')

    return (
        <>
            <Box sx={{
                margin: isMobile ? '.8rem' : '4rem 10vw',
                borderRadius: '20px',
                background: '#f4f4f4',
                padding: isMobile ? '2rem 0' : '2rem .2rem',
                fontFamily: 'catamaran',
            }}>
                <Typography variant={isMobile ? 'h4' : 'h3'} component="h3"
                    sx={{
                        color: 'primary.main',
                        fontFamily: 'inherit',
                        fontWeight: 900,
                        textAlign: 'center',
                        m: '0 0 2rem 0',
                    }}>
                    Why You'll Love Vibio
                </Typography>

                {/* outer container */}
                <Box sx={{
                    m: isMobile ? '1rem 0' : '2rem 1rem',
                    p: isMobile ? '1rem 1.2rem' : '2rem 1rem',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : (`${alignment}` === 'right' ? 'row-reverse' : 'row'),
                    justifyContent: 'space-evenly',
                    alignItems: 'initial',
                    alignContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 2,
                }}>
                    {benefits.map(benefit => {
                        return (
                            <Paper
                                key={benefit.pos}
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    gap: 2,
                                    m: 0,
                                    p: 2,
                                    justifyContent: 'start',
                                    borderRadius: '20px',
                                    background: '#fcfcfc',
                                }}>

                                {/* title */}
                                <Typography variant={isMobile ? 'h5' : 'h5'} component="h2"
                                    sx={{
                                        fontFamily: 'inherit',
                                        fontWeight: 900,
                                        textDecoration: '3px underline #FF1493',
                                    }}>
                                    {(benefit.pos) + '. ' + benefit.title}
                                </Typography>

                                {/* content text */}
                                <Typography variant='body1' component="p" sx={{
                                    fontFamily: 'inherit',
                                    fontWeight: 500,
                                    color: 'text.second',
                                    textAlign: 'left',
                                }}>
                                    {benefit.content}
                                </Typography>

                                {/* get started button */}
                                {/* <Button
                                    type="button"
                                    variant="contained"
                                    disableElevation
                                    sx={{
                                        p: '.6rem 2rem',
                                        fontFamily: 'inherit',
                                        backgroundColor: 'primary.main',
                                        color: 'text.regular',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => navigate("/login")}
                                >
                                    Get Started
                                </Button> */}
                            </Paper>
                        )
                    })}



                    {/* image box */}
                    {/* <Box sx={{
                        flex: 1,
                        p: 1,
                        alignSelf: 'center',
                    }}>
                        <CardMedia
                            component="img"
                            // height="380px"
                            width="auto"
                            image="https://picsum.photos/400"
                            alt="Reasons to use Vibio, image"
                            sx={{
                                borderRadius: '20px',
                                justifySelf: 'center',
                            }}
                        />
                    </Box> */}

                </Box>
            </Box >
        </>
    )
}
