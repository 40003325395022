import BenefitsSection from "../../landing/BenefitsSection"
import FAQSection from "../../landing/FAQSection"
import Footer from "../../landing/Footer"
import GetStartedReminderSection from "../../landing/GetStartedReminderSection"
import HookSection from "../../landing/HookSection"
import ProblemStatement from "../../landing/ProblemStatement"
import Navbar from "../../nav/Navbar"


export default function LandingPageView() {
    return (
        <>
            <Navbar />
            <HookSection />
            <ProblemStatement />
            <BenefitsSection />
            <GetStartedReminderSection />
            <FAQSection />
            <Footer />
        </>
    )
}
