import { Box, Typography, useMediaQuery } from "@mui/material"
import React from "react"

export default function DesignTab({ user }) {

    // check tier before rendering the page
    // render based on tier

    return (
        <>
            <Box sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-evenly",
                marginBottom: '2rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        fontWeight: 500,
                        fontFamily: "inherit",
                    }}
                >
                    Coming soon!
                    Can't wait to see your personalized bio through vibrant colors!
                </Typography>
                <img
                    class="fit-picture"
                    src="/customization.png"
                    alt="Vibio customization placeholder image"
                    style={{
                        width: '180px',
                    }}
                />
            </Box>
        </>
    )
}
